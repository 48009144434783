import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { auth } from './firebase';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios'; // Make sure to import axios
import { FaCheck, FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Add this import at the top of the file
import { useNavigate } from 'react-router-dom'; // Add this import

// Initialize Firestore with the same app instance

// Initialize Stripe payments with the same Firestore instance
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const ToggleLabel = styled.span`
  margin: 0 10px;
  color: ${props => props.isActive ? '#fff' : '#888'};
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;



const Tooltip = styled.span`
  position: relative;
  display: inline-block;
  cursor: pointer;

  &::after {
    content: attr(data-tooltip);
    white-space: pre-wrap;
    position: absolute;
    bottom: 100%; /* Position the tooltip above the text */
    left: 50%;
    transform: translateX(-50%);
    background-color: #555;
    color: #fff;
    text-align: left;
    padding: 10px;
    width: 250px;
    border-radius: 6px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
  }

  &:hover::after {
    visibility: visible;
    opacity: 1;
  }
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSlider} {
    background-color: #2196F3;
  }

  &:checked + ${ToggleSlider}:before {
    transform: translateX(26px);
  }
`;

const PricingSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 40px auto;
  flex-wrap: wrap;
  // mobile
  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const PricingBox = styled.div`
  background-color: #2c3e50;
  border-radius: 10px;
  padding: 30px;
  width: 250px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  // mobile
  @media (max-width: 768px) {
    max-width: 72%;
  }
`;

const PackageTitle = styled.h3`
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #ecf0f1;
`;

const CreditsDisplay = styled.div`
  font-size: 2.5em;
  font-weight: bold;
  color: #3498db;
  margin-bottom: 20px;
`;

const EstimationContainer = styled.div`
  margin-bottom: 30px;
  text-align: left;
`;

const EstimationDetail = styled.div`
  color: #ecf0f1; // clearer color is
  // bolder
  font-weight: bold;
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

const CheckIcon = styled(FaCheck)`
  color: #2ecc71;
  margin-right: 5px;
  min-width: 16px;
  min-height: 16px;
`;

const CrossIcon = styled(FaTimes)`
  color: #e74c3c;
  margin-right: 5px;
  min-width: 16px;
  min-height: 16px;
`;

const PriceContainer = styled.div`
  margin-top: 20px;
`;

const OriginalPrice = styled.span`
  font-size: 1.2em;
  text-decoration: line-through;
  color: #95a5a6;
  display: block;
`;

const CurrentPrice = styled.span`
  font-size: 2em;
  font-weight: bold;
  color: #2ecc71;
`;

const PerPeriod = styled.span`
  font-size: 0.5em;
  color: #95a5a6;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  vertical-align: middle;
`;

const CreditsPeriod = styled.span`
  font-size: 0.5em;
  color: #95a5a6;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  vertical-align: middle;
`;

const DiscountBadge = styled.span`
  background-color: #e74c3c;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8em;
  position: absolute;
  top: -10px;
  right: -10px;
  // mobile
  @media (max-width: 768px) {
    font-size: 0.7em;
  }
`;

const PricingBoxButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
`;

const CenteredHeading = styled.h1`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  // mobile
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const PayButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #888;
    cursor: not-allowed;
  }
`;

const PricingInfoText = styled.p`
  font-size: 0.9em;
  color: #888;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px;

  @media (max-width: 768px) {
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const MostPopularBadge = styled.span`
background-color: #f1c40f;
color: #2c3e50;
padding: 5px 10px;
border-radius: 15px;
font-size: 0.8em;
position: absolute;
top: -10px;
left: -10px;
font-weight: bold;
// Mobile responsiveness
@media (max-width: 768px) {
  font-size: 0.7em;
}
`;

const SubHeading = styled.p`
  text-align: center;
  font-size: 1.1em;
  color: #999; // clearer color is 
  margin-bottom: 30px;
  margin-left: 40px;
  margin-right: 40px;
  // mobile
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const PaymentOptions = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PaymentButton = styled.button`
  background-color: ${props => props.disabled ? '#888' : '#4CAF50'};
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.disabled ? '#888' : '#45a049'};
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
  }
`;

const Greeting = styled.p`
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 20px;
  color: #4CAF50;
`;

const EmailHighlight = styled.span`
  font-weight: bold;
  color: #2196F3;
`;

const CustomInput = styled.input`
  width: 80%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
  border: 1px solid #4CAF50;
  border-radius: 5px;
  font-size: 1em;
  text-align: center;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const CustomAmountDisplay = styled.div`
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
`;

const NotificationBar = styled.div`
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const FAQSection = styled.div`
  margin-top: 50px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  margin-bottom: 50px;
`;

const FAQItem = styled.div`
  margin-bottom: 15px;
  border: 1px solid #3498db;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 2px 10px rgba(52, 152, 219, 0.2);
  }
`;

const FAQQuestion = styled.div`
  color: #ecf0f1;
  background-color: #2c3e50;
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #34495e;
  }

  @media (max-width: 768px) {
    padding: 12px 15px; // Slightly less padding on mobile
    font-size: 0.9em; // Slightly smaller font on mobile
  }
`;

const FAQAnswer = styled.div`
  color: #ecf0f1;
  background-color: #34495e;
  max-height: ${props => props.isOpen ? '1000px' : '0'};
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease;
  padding: ${props => props.isOpen ? '15px 20px' : '0 20px'};
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  transition: background-color 0.3s ease;
  flex-shrink: 0; // Add this line

  ${FAQQuestion}:hover & {
    background-color: #2980b9;
  }

  @media (max-width: 768px) {
    width: 20px; // Slightly smaller on mobile
    height: 20px;
  }
`;

const BackButton = styled.button`
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #2980b9;
  }
`;

const Checkout = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isPaidPlan, setIsPaidPlan] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [userId, setUserId] = useState(null);
  const [creditsSpent, setCreditsSpent] = useState(0);
  const [isDiscountEligible, setIsDiscountEligible] = useState(false);
  const [openFAQs, setOpenFAQs] = useState({});
  const [isYearly, setIsYearly] = useState(true);  // Set default to true for yearly
  const [freeTrialUsed, setFreeTrialUsed] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  const formatNumber = (num) => {
    return Number(num).toLocaleString();
  };

  const subscriptions = {
    freeTrial: {
      name: 'Starter',
      monthlyPrice: '$0',
      yearlyPrice: '$0',
      credits: '5',
      estimatedPictures: 33,
      estimatedModels: 1,
      fixedYearlyCost: 0,
      monthlyStripePriceId: 'price_1Q1Uu303eI7eG1NfZNBdsS7Q', // Free trial price ID
      yearlyStripePriceId: 'price_1Q1Uu303eI7eG1NfZNBdsS7Q',   // Same as monthly for free trial
    },
    basic: {
      name: 'Creator',
      monthlyPrice: '$20',
      yearlyPrice: '$180',
      credits: '240',
      estimatedPictures: 1600,
      estimatedModels: 80,
      fixedYearlyCost: 0.113,
      monthlyStripePriceId: 'price_1Q4jme03eI7eG1NfECAA6XIz',
      yearlyStripePriceId: 'price_1Q4jme03eI7eG1NfF99lgCZb',
    },
    pro: {
      name: 'Artist',
      monthlyPrice: '$60',
      yearlyPrice: '$540',
      credits: '720',
      estimatedPictures: 4800,
      estimatedModels: 240,
      monthlyStripePriceId: 'price_1Q4jnq03eI7eG1NfTPKU9IbO',
      yearlyStripePriceId: 'price_1Q4jnq03eI7eG1NfkvRvNUmz',
      isMostPopular: true, // Add this line
    },
    enterprise: {
      name: 'Visionary',
      monthlyPrice: '$180',
      yearlyPrice: '$1620',
      credits: '2160',
      estimatedPictures: 14400,
      estimatedModels: 720,
      monthlyStripePriceId: 'price_1Q4jl103eI7eG1NfEKrhiCR9',
      yearlyStripePriceId: 'price_1Q4jl103eI7eG1NfqFfgWaAd',
    },
  };

  const getMonthlyPrice = (yearlyPrice) => {
    return `$${(parseFloat(yearlyPrice.replace('$', '')) / 12).toFixed(0)}`;
  };

  const getMonthlyCredits = (yearlyCredits) => {
    return Math.round(parseInt(yearlyCredits) / 12);
  };

  const getCostPerImage = (price, estimatedPictures, fixedYearlyCost) => {
    if (isYearly && fixedYearlyCost) {
      return fixedYearlyCost.toFixed(3);
    }
    const monthlyPrice = isYearly ? parseFloat(price.replace('$', '')) / 12 : parseFloat(price.replace('$', ''));
    const monthlyPictures = estimatedPictures / 12;
    return (monthlyPrice / monthlyPictures).toFixed(3);
  };


  const freeTrialPackage = { price: '$0', credits: '5 credits', stripePriceId: 'price_1Q1Uu303eI7eG1NfZNBdsS7Q', estimatedPictures: 33, estimatedModels: 1 };

  const paidPackages = {
    small: { price: '$20', credits: '20 credits', stripePriceId: 'price_1Pux8T03eI7eG1NfYzZnCaoX', estimatedPictures: 133, estimatedModels: 6 },
    medium: { price: '$50', credits: '50 credits', stripePriceId: 'price_1Pux8T03eI7eG1Nf5ntcIY2L', estimatedPictures: 333, estimatedModels: 16 },
    large: { price: '$100', credits: '100 credits', stripePriceId: 'price_1Q2AZu03eI7eG1NfRYbKZ4Mw', estimatedPictures: 666, estimatedModels: 32},
  };

  useEffect(() => {
    const fetchUserData = async (user) => {
    //   console.log('Fetching user data for:', user.uid);
      setUserEmail(user.email);
      setUserId(user.uid);
      await fetchCreditsSpent(user.uid);
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('User authenticated:', user.uid);
        fetchUserData(user);
      } else {
        console.log("No user is currently signed in.");
        setUserEmail(null);
        setUserId(null);
        setCreditsSpent(0);
        setIsDiscountEligible(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchCreditsSpent = async (uid) => {
    // console.log('Fetching credits spent for user:', uid);
    try {
      const response = await axios.get(`https://fastapi-app-ok2y6f2snq-uc.a.run.app/credits?user_id=${uid}`);
    //   console.log('API response:', response.data);
      
      if (response.data && typeof response.data.credits_spent === 'number') {
        // console.log('Credits spent:', response.data.credits_spent);
        setCreditsSpent(response.data.credits_spent);
        setIsDiscountEligible(response.data.credits_spent <= 0);
        setSubscriptionType(response.data.subscription_type);
        setSubscriptionStatus(response.data.subscription_status);
        setFreeTrialUsed(response.data.free_trial_used);
      } else {
        // console.log('Invalid or missing credits_spent in response. Setting to 0.');
        setCreditsSpent(0);
        setIsDiscountEligible(true);
        setFreeTrialUsed(false);
      }
    } catch (error) {
      console.error('Error fetching credits spent:', error);
    //   console.log('Setting credits spent to 0 due to error.');
      setCreditsSpent(0);
      setIsDiscountEligible(true);
    }
    
    // console.log('Final creditsSpent value:', creditsSpent);
    // console.log('Is discount eligible:', isDiscountEligible);
  };

  const handlePayment = async (packageKey, isFreeTrialPackage = false) => {
    setIsLoading(true);
    
    try {
      const stripe = await stripePromise;
      const packageDetails = isFreeTrialPackage ? freeTrialPackage : paidPackages[packageKey];

      let requestBody = {
        priceId: packageDetails.stripePriceId,
        successUrl: `${window.location.origin}/success`,
        cancelUrl: `${window.location.origin}/checkout`,
        userId: userId,
        userEmail: userEmail,
        isFreeTrial: isFreeTrialPackage,
      };

      if (!isFreeTrialPackage) {
        requestBody.amount = parseInt(packageDetails.price.replace('$', ''));
      }

      const response = await fetch('https://create-checkout-session-ok2y6f2snq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { sessionId } = await response.json();

      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

      if (error) {
        console.error('Error:', error);
        alert('An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatPrice = (price) => {
    return parseFloat(price.replace('$', '')).toFixed(0);
  };

  const getDiscountRate = (packageKey) => {
    if (creditsSpent <= 5) {
      return 0.25; // 25% discount for all packages
    } else {
      if (packageKey === 'medium') return 0.10; // 10% off for $20 package
      if (packageKey === 'large') return 0.15; // 15% off for $50 package
    }
    return 0; // No discount for small package or other cases
  };

  const faqItems = [

    {
      question: "Is my uploaded content secure?",
      answer: "Yes, your art and photos are securely stored and used solely for generating your custom AI models. We respect your privacy and do not share your content."
    },
    {
      question: "Can I use the generated images commercially?",
      answer: "Absolutely! With our paid plans, you have full commercial rights to use the AI-generated images in your projects."
    },
    {
      question: "Is my payment information secure?",
      answer: "Yes, all payments are processed securely through Stripe, a PCI-DSS compliant payment processor. We do not store any payment information on our servers."
    },
    {
      question: "Do credits roll over if I don't use them?",
      answer: "Credits do roll over in monthly and yearly plans. If you don't use all of your credits in a month/year, you can use them in the next month/year."
    },
        {
          question: "If I subscribe to a yearly plan, do I get all the credits in one go?",
          answer: "Yes, you get all the credits in one go."
        },
        {
          question: "Can I switch to another monthly or yearly plan? Will I keep my credits?",
          answer: "Yes, you can switch to a monthly plan or yearly plan at any time. You can do this by going into Settings and then clicking Upgrade or Downgrade. You will keep your current plan's credits. Note that you will get charged immediately for the new plan since the new plan credits are added to your account immediately."
        },
        {
          question: "How do I cancel my subscription?",
          answer: "You can cancel your subscription by going into Settings and then clicking Cancel Subscription. You will keep your credits and access to the app. Credits do not expire, so you can use them at any time without even being subscribed."
        },
        {
          question: "Do you offer refunds for paid plans?",
          answer: "No, we do not offer refunds for paid plans. Credits are granted instantly and do not expire, so you can use them at any time."
        },
        {
          question: "Do credits expire?",
          answer: "No, credits do not expire. You can use them at any time."
        },
        {
          question: "Can I buy one time packages if I'm subscribed?",
          answer: "Yes, you can buy them. The credits will be added to your account on top of your current credits."
        },
        {
          question: "Are the images censored?",
          answer: "If you are a paid user they are not censored. You are responsible for the images you generate. Please follow our terms of service."
        },
  ];



  const toggleFAQ = (index) => {
    setOpenFAQs(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const handleBackToApp = () => {
    navigate('/app');
  };
  const handleSubscription = async (subscriptionKey) => {
    // Check if user already has an active subscription
    if (subscriptionStatus === 'active') {
      window.location.href = 'https://billing.stripe.com/p/login/8wMcQj7fob0Z0Mw3cc';
      return;
    }

    setIsLoading(true);

    try {
      const stripe = await stripePromise;
      const subscriptionDetails = subscriptions[subscriptionKey];
      const priceId = isYearly ? subscriptionDetails.yearlyStripePriceId : subscriptionDetails.monthlyStripePriceId;
      
      const requestBody = {
        priceId: priceId,
        successUrl: `${window.location.origin}/success`,
        cancelUrl: `${window.location.origin}/checkout`,
        userId: userId,
        userEmail: userEmail,
        isSubscription: true,
        isYearly: isYearly,
      };

      // Add 'isFreeTrial' flag if the selected subscription is the free trial
      if (subscriptionKey === 'freeTrial') {
        requestBody.isFreeTrial = true;
        requestBody.isSubscription = false; // Set to false for free trial
      }

      const response = await fetch('https://create-checkout-session-ok2y6f2snq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const { sessionId } = await response.json();
      
      const { error } = await stripe.redirectToCheckout({ sessionId });
      
      if (error) {
        console.error('Error:', error);
        alert('An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <CenteredHeading>Unlock your creative potential</CenteredHeading>
      <SubHeading>Choose a plan and start generating custom AI images from your own art or photos.</SubHeading>

      <ToggleContainer>
        <ToggleLabel isActive={!isYearly}>Monthly</ToggleLabel>
        <ToggleSwitch>
          <ToggleInput type="checkbox" checked={isYearly} onChange={() => setIsYearly(!isYearly)} />
          <ToggleSlider />
        </ToggleSwitch>
        <ToggleLabel isActive={isYearly}>Yearly (Save 25%)</ToggleLabel>
      </ToggleContainer>

      <PricingSection>
        {Object.entries(subscriptions)
          // Filter out 'freeTrial' if creditsSpent >= 0.3
          .filter(([key]) => key !== 'freeTrial' || creditsSpent < 0)
          .map(([key, value]) => (
            <PricingBox key={key}>
              {value.isMostPopular && (
                <MostPopularBadge>Most Popular</MostPopularBadge>
              )}
              <PackageTitle>{value.name}</PackageTitle>
              <CreditsDisplay>
                {isYearly 
                  ? `${value.credits} credits` 
                  : key === 'freeTrial' 
                    ? `${value.credits} credits` 
                    : `${getMonthlyCredits(value.credits)} credits`}
              </CreditsDisplay>
              <EstimationContainer>
                <EstimationDetail>
                  <CheckIcon /> {' '}
                  {key === 'freeTrial'
                    ? formatNumber(value.estimatedPictures)
                    : isYearly
                    ? formatNumber(value.estimatedPictures)
                    : formatNumber(Math.round(value.estimatedPictures / 12))}{' '}
                  custom AI images
                </EstimationDetail>
                <EstimationDetail>
                  <CheckIcon />  {
                    key === 'freeTrial' 
                      ? value.estimatedModels 
                      : isYearly 
                        ? value.estimatedModels 
                        : Math.round(value.estimatedModels / 12)
                  } custom AI image generators
                </EstimationDetail>
                <EstimationDetail>
  <CheckIcon /> Generate custom AI images
</EstimationDetail>
                <EstimationDetail>
  <CheckIcon /> Edit custom AI images
</EstimationDetail>

                <EstimationDetail>
  <CheckIcon /> Preserve your artistic style
</EstimationDetail>
<EstimationDetail>
  <CheckIcon /> Consistent characters
</EstimationDetail>
{key !== 'freeTrial' && (
                  <EstimationDetail>
                    <CheckIcon /> High quality images with up to 4K resolution
                  </EstimationDetail>
                )}
                {key !== 'freeTrial' && (
                  <EstimationDetail>
                    <CheckIcon /> Commercial use
                  </EstimationDetail>
                )}

              </EstimationContainer>
              <PriceContainer>
                <CurrentPrice>
                  {isYearly ? getMonthlyPrice(value.yearlyPrice) : value.monthlyPrice}
                  <PerPeriod> / month</PerPeriod>
                </CurrentPrice>
              </PriceContainer>
              {/* Hide cost per image for free trial */}
              {key !== 'freeTrial' && (
                <small style={{ display: 'block', color: '#c7c5c5', marginTop: '10px' }}>
                  ${getCostPerImage(isYearly ? value.yearlyPrice : value.monthlyPrice, value.estimatedPictures, value.fixedYearlyCost)} per custom image
                </small>
              )}
              <PricingBoxButton
                disabled={isLoading}
                onClick={
                  () => {
                    if (key === 'freeTrial') {
                      if (freeTrialUsed) {
                        // Redirect to /app if free_trial_used is true
                        navigate('/successtrial');
                      } else {
                        // Proceed to onboarding
                        navigate('/onboarding');
                      }
                    } else {
                      handleSubscription(key);
                    }
                  }}
              >
                {isLoading
                  ? 'Processing...'
                  : key === 'freeTrial'
                    ? 'Start Free Trial'
                    : 'Subscribe'}
              </PricingBoxButton>
            {key === 'freeTrial' && (
              <small style={{ display: 'block', color: '#c7c5c5', marginTop: '10px' }}>
                No payment details needed
              </small>
            )}
            </PricingBox>
          ))}
      </PricingSection>

      {creditsSpent >= 0 && (
        <>
          <CenteredHeading>Or choose a one-time credit package</CenteredHeading>

          <PricingSection>
            {Object.entries(paidPackages).map(([key, value]) => (
              <PricingBox key={key}>
                <PackageTitle>{key.charAt(0).toUpperCase() + key.slice(1)} Package</PackageTitle>
                <CreditsDisplay>
                  {value.credits}
                </CreditsDisplay>
                <EstimationContainer>
                  <EstimationDetail><CheckIcon /> {value.estimatedPictures} custom AI images</EstimationDetail>
                  <EstimationDetail><CheckIcon /> {value.estimatedModels} custom AI image generators</EstimationDetail>
                  
                  <EstimationDetail><CheckIcon /> Includes the same features as subscription plans</EstimationDetail>
                </EstimationContainer>
                <PriceContainer>
                  <CurrentPrice>{value.price}</CurrentPrice>
                </PriceContainer>
                <small style={{display: 'block', color: '#c7c5c5', marginTop: '10px'}}>
                  ${(parseFloat(value.price.replace('$', '')) / value.estimatedPictures).toFixed(3)} per custom image
                </small>
                <PricingBoxButton
                  disabled={isLoading}
                  onClick={() => handlePayment(key)}
                >
                  {isLoading ? 'Processing...' : 'Buy'}
                </PricingBoxButton>
              </PricingBox>
            ))}
          </PricingSection>
        </>
      )}

      <FAQSection>
        <CenteredHeading>Frequently Asked Questions</CenteredHeading>
        {faqItems.map((item, index) => (
          <FAQItem key={index}>
            <FAQQuestion onClick={() => toggleFAQ(index)}>
              {item.question}
              <IconWrapper>
                {openFAQs[index] ? <FaChevronUp size={14} /> : <FaChevronDown size={14} />}
              </IconWrapper>
            </FAQQuestion>
            <FAQAnswer isOpen={openFAQs[index]}>
              {item.answer}
            </FAQAnswer>
          </FAQItem>
        ))}
      </FAQSection>

      {creditsSpent > 0 && (
                <Footer>
                    <BackButton onClick={handleBackToApp}>Go back to App</BackButton>
                </Footer>
            )}
    </div>
  );
};

const stripePromise = loadStripe('pk_live_51PufGf03eI7eG1Nf5ck8ESBKiBpIz7pVD5EEWWeLNTHUJfpVj63DsEVpfgIUfgGjLzRQQariv90ejHxnWn4Fvitw00gW5wfYVM');

export default Checkout;